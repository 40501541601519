import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { ClientsApiService } from 'src/app/core/clients-api/clients-api.service';
import { ComponentCanDeactivate } from 'src/app/core/guards/can-deactivate.guard';
import MandateDto from 'src/app/service/dto/MandateDto';
import MandateSigner from 'src/app/service/model/MandateSigner';
import MandateType from 'src/app/service/model/MandateType';
import NaturalPerson from 'src/app/service/model/NaturalPerson';
import PersonType from 'src/app/service/model/PersonType';
import { ToastService } from 'src/app/service/toast.service';
import { MandatesApiService } from '../../core/mandates-api/mandates-api.service';
import Client from '../../service/model/Client';
import Mandate from '../../service/model/Mandate';
import MandatesConcept from '../../service/model/MandateConcept';
import { CreateConceptCommand, CreateConceptDto } from 'src/app/service/dto/ConceptCommand';
import { BulkClientFormGroupType } from 'src/app/clients/form/bulk-concept-form';
import { PublishBulkMandateDialog } from './publish-bulk-mandate-dialog/publish-bulk-mandate-dialog';
import { cloneDeep } from 'lodash';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { TranslateService } from '@ngx-translate/core';
import BulkConcept from 'src/app/service/dto/BulkConcept';

@Component({
  selector: 'mandates-create-mandate',
  templateUrl: './create-mandate.component.html',
  styleUrls: ['./create-mandate.component.scss'],
})
export class CreateMandateComponent implements OnInit, ComponentCanDeactivate, OnDestroy {
  canNavigateAway: boolean = false;
  cameFromClient: boolean = false;
  mandateTypes: MandateType[];
  client: Client | null;
  clientId: string | null;
  clientIds: string[] | null;
  mandateSigner: MandateSigner | null;

  mandatesConcept?: MandatesConcept;
  mandatesBulkConcept?: BulkConcept;

  allMandatesForm = new UntypedFormGroup({
    endDate: new UntypedFormControl(null),
  });
  mandatesForm: UntypedFormArray = new UntypedFormArray([], [Validators.required]);

  validNaturalPersonTypes = [
    'E705',
    'edepo',
    'DISPUTE',
    'MDT-276CONV',
    'MDT-IPPINR-PRO',
    'MDT_RVPM',
    'NBB'
  ];
  validNaturalPersonWithVATTypes = [
    'E705',
    'edepo',
    'DISPUTE',
    'CUSTOMSPRO',
    'EXCISEPRO',
    'VAT',
    'MDT-276CONV',
    'MDT-IPPINR-PRO',
    'MDT_RVPM',
    'NBB'
  ];

  validMoralPersonTypes = [
    'EXCISEPRO',
    'BEPS13',
    'BIZTAX',
    'VAT',
    'CUSTOMSPRO',
    'E705',
    'edepo',
    'FINPROF',
    'DISPUTE',
    'UBO',
    'OPC_MANDATE',
    'MDT-276CONV',
    'MDT_RVPM',
    'NBB'
  ];

  mandatesConceptId: string | null;
  mandatesBulkConceptId: string | null;
  signerFormIsOpen: boolean = false;

  isSelectingBulkClients: boolean = false;
  bulkClientsForm: FormGroup<BulkClientFormGroupType>[] = [];

  naturalMandateTypes: MandateType[] = [];
  moralMandateTypes: MandateType[] = [];
  naturalMandateWithVATTypes: MandateType[] = [];

  naturalMandatesBulkForm: UntypedFormArray = new UntypedFormArray([], [Validators.required]);
  moralMandatesBulkForm: UntypedFormArray = new UntypedFormArray([], [Validators.required]);

  selectedTabNumber: 0 | 1 = 0;

  allMandatesNaturalForm = new UntypedFormGroup({
    endDate: new UntypedFormControl(null),
  });
  allMandatesMoralForm = new UntypedFormGroup({
    endDate: new UntypedFormControl(null),
  });

  readonly MAX_SELECTABLE_ROWS = 100;

  constructor(
    private route: ActivatedRoute,
    private mandatesApi: MandatesApiService,
    private clientsApi: ClientsApiService,
    private location: Location,
    private toastService: ToastService,
    private matDialog: MatDialog,
    private translateService: TranslateService
  ) {
    this.bulkClientsForm = this.bulkClientsForm.filter((form) => {
      return form.controls.client.value.id !== this.clientId;
    });
  }

  ngOnDestroy(): void {
    this.clientsApi.selectedClient$.unsubscribe();

    if (this.clientsApi.selectedClients$.value.length > 0) {
      this.clientsApi.selectedClients$.next([]);
    }
  }

  ngOnInit(): void {
    this.clientId = this.route.snapshot.queryParamMap.get('clientId');
    const clientIdsString = this.route.snapshot.queryParamMap.get('clientIds');
    this.clientIds = clientIdsString ? clientIdsString.split(',') : null;

    this.mandatesConceptId = this.route.snapshot.queryParamMap.get('mandatesConceptId');
    this.mandatesBulkConceptId = this.route.snapshot.queryParamMap.get('mandatesBulkConceptId');

    forkJoin({
      mandateTypes: this.mandatesApi.getMandateTypes(),
      client: this.clientId ? this.clientsApi.getClientById(this.clientId) : of(null),
    }).subscribe(({ mandateTypes, client }) => {
      this.mandateTypes = mandateTypes;

      this.naturalMandateTypes = cloneDeep(
        mandateTypes.filter((type) => this.validNaturalPersonTypes.includes(type.typeName))
      );

      this.naturalMandateWithVATTypes = cloneDeep(
        mandateTypes.filter((type) => this.validNaturalPersonWithVATTypes.includes(type.typeName))
      );

      this.moralMandateTypes = cloneDeep(
        mandateTypes.filter((type) => this.validMoralPersonTypes.includes(type.typeName))
      );

      if (client) {
        this.client = client;
        this.handleBulkClientSelected(client);
      }
      if (this.clientId && this.mandatesConceptId) {
        this.getExistingConcept(this.mandatesConceptId, this.clientId);
      }

      if (!this.clientId && !this.mandatesConceptId) {
        this.clientsApi.selectedClients$.subscribe((clients) => {
          clients.forEach((client) => {
            this.handleBulkClientSelected(client);
          });
        });
      }

      if (this.clientIds && this.mandatesBulkConceptId) {
        this.getExistingBulkConcept(this.mandatesBulkConceptId);
      }
    });

    this.allMandatesForm.valueChanges.subscribe(({ endDate }) => this.setAllEndDates(endDate));

    this.allMandatesNaturalForm.valueChanges.subscribe(({ endDate }) =>
      this.setAllNaturalEndDates(endDate)
    );

    this.allMandatesMoralForm.valueChanges.subscribe(({ endDate }) =>
      this.setAllMoralEndDates(endDate)
    );
  }

  setAllEndDates(endDate: Date) {
    this.mandatesForm.controls.forEach((control) =>
      (control as UntypedFormGroup).patchValue({ endDate: endDate })
    );
  }

  getExistingConcept(conceptId: string, clientId: string) {
    this.mandatesApi.getConceptById(conceptId, clientId).subscribe((mandatesConcept) => {
      this.mandatesConcept = mandatesConcept;
      this.initFromMandatesConcept(mandatesConcept);
      if (mandatesConcept.mandateSigner) {
        this.mandateSigner = mandatesConcept.mandateSigner;

        const formGroup = this.bulkClientsForm.find(
          (form) => form.controls.client.value.id === clientId
        )!;
        formGroup.controls.mandateSigner.setValue(mandatesConcept.mandateSigner);
      }
    });
  }

  getExistingBulkConcept(conceptId: string) {
    this.mandatesApi.getConceptByBulkId(conceptId).subscribe((bulkConcept) => {
      this.mandatesBulkConcept = bulkConcept;
      this.initFromMandatesBulkConcept(bulkConcept);

      bulkConcept.concepts.map((c) => {
        this.clientsApi.getClientById(c.clientReference).subscribe((client) => {
          this.handleBulkClientSelected(client, c.mandates[0]?.mandateSigner);
        });
      });
    });
  }

  initFromMandatesBulkConcept(bulkConcept: BulkConcept) {
    const naturalMandates = bulkConcept.concepts.filter(
      (c) => c.clientType === PersonType.NATURAL_PERSON
    )[0];

    // TODO fix me - dates!!
    naturalMandates?.mandates.forEach((mandate) => {
      this.onNaturalMandateTypeClick(mandate.mandateType.id);
      const val = this.naturalMandatesBulkForm.value.findIndex(
        (c: any) => c.mandateType === mandate.mandateType.id
      );

      // this.naturalMandatesBulkForm.at(val).value.endDate = mandate.endDate;
      // this.naturalMandatesBulkForm.at(val).updateValueAndValidity();
    });

    const moralMandates = bulkConcept.concepts.filter(
      (c) => c.clientType === PersonType.MORAL_PERSON
    )[0];

    moralMandates?.mandates.forEach((mandate) => {
      this.onMoralMandateTypeClick(mandate.mandateType.id);
    });
  }

  initFromMandatesConcept(mandatesConcept: MandatesConcept) {
    mandatesConcept.mandates.forEach((mandate) => {
      this.mandateTypes.find((type) => type.id === mandate.mandateType.id)!.isSelected = true;
      this.addFormGroup(mandate.mandateType, mandate);
    });
  }

  canDeactivate() {
    return {
      shouldDeactivate: this.canNavigateAway,
      data: {
        title: !this.mandatesConceptId
          ? 'DIALOG.CANCEL-FORM.DESCRIPTION-MANDATE-CREATE'
          : 'DIALOG.CANCEL-FORM.DESCRIPTION-MANDATE-UPDATE',
        description: '',
        cancelText: 'DIALOG.NO-CONTINUE',
        confirmText: 'DIALOG.YES-CANCEL',
        confirmColor: 'accent',
      },
    };
  }

  navigateAway(force: boolean = false) {
    if (force) this.canNavigateAway = true;
    this.location.back();
    /* if (this.mandatesConceptId) {
      this.router.navigate(['/mandates']);
    } else {
      this.router.navigate(['/clients']);
    } */
  }

  onMandateTypeClick(id: string) {
    const mandateType = this.mandateTypes.find((type) => type.id === id)!;
    mandateType.isSelected = !mandateType.isSelected;

    mandateType.isSelected ? this.addFormGroup(mandateType) : this.removeFormGroup(mandateType.id);
  }

  save(shouldPublish: boolean = false) {
    this.mandatesForm.markAllAsTouched();
    if (this.mandatesForm.valid) {
      const mandates = this.mandatesForm.value as MandateDto[];
      const conceptCommand = {
        mandates: mandates,
        mandateSignerReference: this.mandateSignerId,
      };

      if (this.mandatesConcept) {
        this.mandatesApi
          .updateConcept(this.mandatesConcept.id, this.client!.id, conceptCommand)
          .subscribe({
            next: (response) => {
              if (shouldPublish) {
                this.publishMandates(response);
              } else {
                this.toastService.openConceptCreatedToast(this.client!!.person.getScreenName());
                this.navigateAway(true);
              }
            },
            error: ({ error }) => this.handleErrors(error),
          });
      } else {
        this.mandatesApi
          .createConcept({ concepts: [{ clientId: this.client!!.id, ...conceptCommand }] })
          .subscribe((response) => {
            if (shouldPublish) {
              this.publishMandates(response[0]);
            } else {
              this.toastService.openConceptCreatedToast(this.client!!.person.getScreenName());
              this.navigateAway(true);
            }
          });
      }
    } else {
      this.toastService.openValidationToast();
    }
  }

  cancel() {
    this.isSelectingBulkClients ? (this.isSelectingBulkClients = false) : this.navigateAway();
  }

  removeClient() {
    this.clientId = null;
    this.client = null;
    this.mandateSigner = null;
    this.removeAllSelectedMandates();
  }

  removeMandateSigner() {
    this.mandateSigner = null;
  }

  handleClientSelected(client: Client) {
    this.clientId = client.id;
    this.client = client;
  }

  handleOnToggle(isOpen: boolean) {
    this.signerFormIsOpen = isOpen;
  }

  onSignerSaved(signer: MandateSigner) {
    this.mandateSigner = signer;
  }

  get mandateSignerId() {
    if (this.mandateSigner) return this.mandateSigner.id;
    return null;
  }

  get mandatesFormGroups(): UntypedFormGroup[] {
    return this.mandatesForm.controls as UntypedFormGroup[];
  }

  get naturalPerson(): NaturalPerson | null {
    if (this.client!.person.getPersonType() === PersonType.NATURAL_PERSON) {
      return this.client!.person as NaturalPerson;
    }
    return null;
  }

  private addFormGroup(mandateType: MandateType, mandate?: Mandate) {
    this.mandatesForm.push(
      new UntypedFormGroup({
        mandateType: new UntypedFormControl(mandateType.id),
        mandateTypeName: new UntypedFormControl(mandateType.typeName),
        endDate: new UntypedFormControl(mandate?.endDate),
      })
    );
  }

  private removeFormGroup(id: string) {
    const index = this.mandatesForm.value.findIndex(
      ({ mandateType }: { mandateType: string }) => mandateType === id
    );
    this.mandatesForm.removeAt(index);
  }

  get canPublish(): boolean {
    return this.mandatesForm.valid && this.mandateSigner ? true : false;
  }

  private publishMandates(concept: MandatesConcept) {
    this.mandatesApi.publishConcept(concept.id, concept.clientId, concept.mandateSigner).subscribe({
      next: () => {
        this.toastService.openMandatePublishedToast(concept.mandates.length > 1);
        this.navigateAway(true);
      },
      error: ({ error }) => this.handleErrors(error),
    });
  }

  get mandateSignerHeading(): string {
    return this.mandateSigner ? 'MANDATES.SELECTED-MANDATE-SIGNER' : 'MANDATES.FILL-MANDATE-SIGNER';
  }

  get filteredMandateTypes() {
    if (this.client) {
      if (this.client.person.getPersonType() === PersonType.NATURAL_PERSON) {
        if (this.client.person.isVatObligated) {
          return this.mandateTypes.filter((type) =>
            this.validNaturalPersonWithVATTypes.includes(type.typeName)
          );
        } else {
          return this.mandateTypes.filter((type) =>
            this.validNaturalPersonTypes.includes(type.typeName)
          );
        }
      } else {
        return this.mandateTypes.filter((type) =>
          this.validMoralPersonTypes.includes(type.typeName)
        );
      }
    }
    return null;
  }

  public filteredMandateTypesByClient(personType: PersonType, isVatObligated: boolean) {
    if (personType === PersonType.NATURAL_PERSON) {
      if (isVatObligated) {
        return this.mandateTypes.filter((type) =>
          this.validNaturalPersonWithVATTypes.includes(type.typeName)
        );
      } else {
        return this.mandateTypes.filter((type) =>
          this.validNaturalPersonTypes.includes(type.typeName)
        );
      }
    } else {
      return this.mandateTypes.filter((type) => this.validMoralPersonTypes.includes(type.typeName));
    }
  }

  private getSelectedMandateTypes(): MandateType[] {
    return this.mandateTypes.filter((type) => type.isSelected);
  }

  private removeAllSelectedMandates() {
    this.mandateTypes.forEach((mandateType) => {
      mandateType.isSelected = false;
    });

    this.removeAllNaturalMandateBulkFormGroup();
    this.removeAllMoralMandateBulkFormGroup();
    this.mandatesForm.clear();
  }

  private handleErrors(error: any) {
    console.error(error);
    const errors: { defaultMessage: string }[] = error.errors;

    if (errors && errors.length > 0) {
      errors.map((error) => {
        this.toastService.openErrorToast(error.defaultMessage);
      });
    }

    if ((error.message as string).includes('SERVER-ERROR.NBB-MANDATE-ALREADY-EXISTS')) {
      let messageKey = error.message.replace(/"/g, '');
      this.toastService.openErrorToast(this.translateService.instant(messageKey));
    } else {
      this.toastService.openErrorToast(`[${error.status}: ${error.error}] ${error.message}`);
    }

    console.error(error.message);
    console.error({ error });
  }

  get isEdit(): boolean {
    return this.mandatesConceptId || this.mandatesBulkConceptId ? true : false;
  }

  get naturalClientsFromBulkForm() {
    return this.bulkClientsForm.filter(
      (form) => form.controls.client.value.person.getPersonType() === PersonType.NATURAL_PERSON
    );
  }

  get moralClientsFromBulkForm() {
    return this.bulkClientsForm.filter(
      (form) => form.controls.client.value.person.getPersonType() === PersonType.MORAL_PERSON
    );
  }

  private addBulkClientFormGroup(client: Client, mandateSigner?: MandateSigner) {
    if (this.bulkClientsForm.find((form) => form.controls.client.value.id === client.id)) return;

    const formGroup = new FormGroup({
      client: new FormControl<Client>(client, { nonNullable: true }),
      mandateSigner: new FormControl<MandateSigner | null>(mandateSigner ?? null),
    });

    this.bulkClientsForm.push(formGroup);

    let companiesFound = false;
    let noCompaniesFound = true; // Assume all without company number initially

    this.bulkClientsForm.forEach((form) => {
      const clientData = form.controls.client.value.person;
      if (clientData.companyNumber) {
        companiesFound = true;
        // No need to keep searching if a company number is found
        return; // Exit the loop early
      }
    });

    if (companiesFound && noCompaniesFound) {
      // TODO grey out VAT options
      this.naturalMandateTypes = this.filteredMandateTypesByClient(PersonType.NATURAL_PERSON, true); // Mixed
    } else if (companiesFound && !noCompaniesFound) {
      this.naturalMandateTypes = this.filteredMandateTypesByClient(PersonType.NATURAL_PERSON, true); // All with company number
    } else {
      this.naturalMandateTypes = this.filteredMandateTypesByClient(
        PersonType.NATURAL_PERSON,
        false
      ); // All without company number (or no data)
    }
  }

  removeBulkClientFormGroup(form: FormGroup<BulkClientFormGroupType>) {
    const clientId = form.controls.client.value.id;
    const index = this.bulkClientsForm.findIndex(
      (form) => form.controls.client.value.id === clientId
    );
    this.bulkClientsForm.splice(index, 1);

    this.removeAllSelectedMandates();
  }

  addMandateSignerToBulkClient(
    form: FormGroup<BulkClientFormGroupType>,
    mandateSigner: MandateSigner
  ) {
    const clientId = form.controls.client.value.id;
    const formGroup = this.bulkClientsForm.find(
      (form) => form.controls.client.value.id === clientId
    )!;
    formGroup.controls.mandateSigner.setValue(mandateSigner);
  }

  removeMandateSignerFromBulkClient(form: FormGroup<BulkClientFormGroupType>) {
    const clientId = form.controls.client.value.id;

    const formGroup = this.bulkClientsForm.find(
      (form) => form.controls.client.value.id === clientId
    )!;

    formGroup.controls.mandateSigner.setValue(null);
  }

  getNaturalPersonFromBulkClientForm(
    form: FormGroup<BulkClientFormGroupType>
  ): NaturalPerson | null {
    if (form.controls.client.value.person.getPersonType() === PersonType.NATURAL_PERSON)
      return form.controls.client.value.person as NaturalPerson;
    return null;
  }

  selectBulkClients() {
    this.isSelectingBulkClients = true;
  }

  handleBulkClientSelected(client: Client, mandateSigner?: MandateSigner) {
    this.selectedTabNumber = client.person.getPersonType() === PersonType.NATURAL_PERSON ? 0 : 1;
    this.isSelectingBulkClients = false;
    this.addBulkClientFormGroup(client, mandateSigner);
  }

  bulkMandateSignerHeading(signer?: MandateSigner): string {
    return signer ? 'MANDATES.SELECTED-MANDATE-SIGNER' : 'MANDATES.FILL-MANDATE-SIGNER';
  }

  onNaturalMandateTypeClick(id: string) {
    const mandateType = this.naturalMandateTypes.find((type) => type.id === id)!;
    mandateType.isSelected = !mandateType.isSelected;

    mandateType.isSelected
      ? this.addNaturalMandateBulkFormGroup(mandateType)
      : this.removeNaturalMandateBulkFormGroup(mandateType.id);
  }

  private addNaturalMandateBulkFormGroup(mandateType: MandateType) {
    this.naturalMandatesBulkForm.push(
      new UntypedFormGroup({
        mandateType: new UntypedFormControl(mandateType.id),
        mandateTypeName: new UntypedFormControl(mandateType.typeName),
        endDate: new UntypedFormControl(null),
      })
    );
  }

  private removeNaturalMandateBulkFormGroup(id: string) {
    const index = this.naturalMandatesBulkForm.value.findIndex(
      ({ mandateType }: { mandateType: string }) => mandateType === id
    );
    this.naturalMandatesBulkForm.removeAt(index);
  }

  private removeAllNaturalMandateBulkFormGroup() {
    this.naturalMandatesBulkForm.value.forEach((_: any, index: number) => {
      this.naturalMandatesBulkForm.removeAt(0);
    });
  }

  private removeAllMoralMandateBulkFormGroup() {
    this.moralMandatesBulkForm.value.forEach((_: any, index: number) => {
      this.moralMandatesBulkForm.removeAt(0);
    });
  }

  onMoralMandateTypeClick(id: string) {
    const mandateType = this.moralMandateTypes.find((type) => type.id === id)!;
    mandateType.isSelected = !mandateType.isSelected;

    mandateType.isSelected
      ? this.addMoralMandateBulkFormGroup(mandateType)
      : this.removeMoralMandateBulkFormGroup(mandateType.id);
  }

  private addMoralMandateBulkFormGroup(mandateType: MandateType) {
    this.moralMandatesBulkForm.push(
      new UntypedFormGroup({
        mandateType: new UntypedFormControl(mandateType.id),
        mandateTypeName: new UntypedFormControl(mandateType.typeName),
        endDate: new UntypedFormControl(null),
      })
    );
  }

  private removeMoralMandateBulkFormGroup(id: string) {
    const index = this.moralMandatesBulkForm.value.findIndex(
      ({ mandateType }: { mandateType: string }) => mandateType === id
    );
    this.moralMandatesBulkForm.removeAt(index);
  }

  get naturalMandatesBulkFormGroups(): UntypedFormGroup[] {
    return this.naturalMandatesBulkForm.controls as UntypedFormGroup[];
  }

  get moralMandatesBulkFormGroups(): UntypedFormGroup[] {
    return this.moralMandatesBulkForm.controls as UntypedFormGroup[];
  }

  saveBulk(shouldPublish: boolean = false) {
    const command: CreateConceptCommand = {
      concepts: [],
    };
    const naturalClientConcepts: CreateConceptDto[] = [];

    const moralClientConcepts: CreateConceptDto[] = [];

    if (this.naturalClientsFromBulkForm.length > 0)
      this.naturalClientsFromBulkForm.forEach((form) => {
        const mandates = this.naturalMandatesBulkForm.value as MandateDto[];

        const concept = {
          id:
            this.mandatesBulkConcept?.concepts.find(
              (c) => c.clientReference === form.controls.client.value.id
            )?.id ?? null,
          clientId: form.controls.client.value.id,
          mandateSignerReference: form.controls.mandateSigner.value?.id ?? null,
          mandates: mandates,
        };
        naturalClientConcepts.push(concept);
      });

    if (this.moralClientsFromBulkForm.length > 0)
      this.moralClientsFromBulkForm.forEach((form) => {
        const mandates = this.moralMandatesBulkForm.value as MandateDto[];
        const concept = {
          id:
            this.mandatesBulkConcept?.concepts.find(
              (c) => c.clientReference === form.controls.client.value.id
            )?.id ?? null,
          clientId: form.controls.client.value.id,
          mandateSignerReference: form.controls.mandateSigner.value?.id ?? null,
          mandates: mandates,
        };
        moralClientConcepts.push(concept);
      });

    command.concepts = [...naturalClientConcepts, ...moralClientConcepts];

    if (this.isEdit) {
      this.mandatesApi
        .updateConcepts(this.mandatesBulkConceptId!! || this.mandatesConceptId!!, command)
        .subscribe({
          next: (response) => {
            if (shouldPublish) {
              response.forEach((r) => this.publishMandates(r));
            } else {
              this.toastService.openConceptsCreatedToast();
              this.navigateAway(true);
            }
          },
          error: ({ error }) => this.handleErrors(error),
        });
    } else {
      this.mandatesApi.createConcept(command).subscribe({
        next: (response) => {
          if (shouldPublish) {
            response.forEach((r) => this.publishMandates(r));
          } else {
            this.toastService.openConceptsCreatedToast();
            this.navigateAway(true);
          }
        },
        error: ({ error }) => this.handleErrors(error),
      });
    }
  }

  naturalPersonsAreAllVAT() {
    return this.naturalClientsFromBulkForm.every((form) => {
      return form.controls.client.value.person.companyNumber;
    });
  }

  publishBulk() {
    this.matDialog
      .open(PublishBulkMandateDialog, {
        data: {
          naturalClientForms: this.naturalClientsFromBulkForm,
          moralClientForms: this.moralClientsFromBulkForm,
          naturalMandateForms: this.naturalMandatesBulkFormGroups,
          moralMandateForms: this.moralMandatesBulkFormGroups,
        },
      })
      .afterClosed()
      .subscribe((response) => {
        if (response) this.saveBulk(true);
        const element = document.querySelector('#header') as any;
        element.scrollIntoView();
      });
  }

  clientsBulkFormIsValid(): boolean {
    if (this.bulkClientsForm.length === 0) return false;

    return this.bulkClientsForm.every((form) => {
      return form.valid;
    });
  }

  mandateBulkFormIsValid(): boolean {
    let naturalFormIsValid = true;
    let moralFormIsValid = true;

    if (this.naturalClientsFromBulkForm.length > 0) {
      if (this.naturalMandatesBulkForm.length === 0) {
        naturalFormIsValid = false;
      } else {
        naturalFormIsValid = this.naturalMandatesBulkForm.controls.every((form) => {
          return form.valid;
        });
      }
    }

    if (this.moralClientsFromBulkForm.length > 0) {
      if (this.moralMandatesBulkForm.length === 0) {
        moralFormIsValid = false;
      } else {
        moralFormIsValid = this.moralMandatesBulkForm.controls.every((form) => {
          return form.valid;
        });
      }
    }

    return naturalFormIsValid && moralFormIsValid;
  }

  canSaveBulk(): boolean {
    return this.clientsBulkFormIsValid() && this.mandateBulkFormIsValid();
  }

  private setAllNaturalEndDates(endDate: Date) {
    this.naturalMandatesBulkForm.controls.forEach((control) =>
      (control as UntypedFormGroup).patchValue({ endDate: endDate })
    );
  }

  private setAllMoralEndDates(endDate: Date) {
    this.moralMandatesBulkForm.controls.forEach((control) =>
      (control as UntypedFormGroup).patchValue({ endDate: endDate })
    );
  }

  canPublishBulk(): boolean {
    const allHaveMandateSigner = this.bulkClientsForm.every((form) => {
      return form.controls.mandateSigner.value !== null;
    });

    return allHaveMandateSigner && this.clientsBulkFormIsValid() && this.mandateBulkFormIsValid();
  }

  onTabChange(event: MatTabChangeEvent) {
    this.selectedTabNumber = event.index ? 1 : 0;
  }

  get selectedTabPersonType(): PersonType {
    return this.selectedTabNumber === 0 ? PersonType.NATURAL_PERSON : PersonType.MORAL_PERSON;
  }

  clientLimitExceeded(): boolean {
    return this.bulkClientsForm.length >= this.MAX_SELECTABLE_ROWS;
  }
}
